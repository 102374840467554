<template lang="pug">
  v-card
    v-card-title
      h2.card-title {{ 'modals.employees.settings.title' | translate }}
    v-card-content
      v-select.mb-3(
        :label="$t('base.user_groups')"
        :options="types"
        itemValue="value"
        v-model="form.user_type")
      v-text-field.mb-3(
        :label="$t('base.first_name')"
        v-model="form.name")
      v-text-field.mb-3(
        :label="$t('base.last_name')"
        v-model="form.surname")
      v-text-field.mb-3(
        :label="$t('base.phone')"
        v-model="form.phone")
      v-text-field.mb-3(
        :label="$t('base.email')"
        v-model="form.email")
      v-password-field(
        :label="$t('base.password')"
        v-model="form.password")
    v-card-actions
      v-btn(
        v-if="'Technologist' === user.user.role"
        :loading="loading"
        @click="onUpdate") {{ 'actions.save' | translate }}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { USER_TYPES } from '@/util/consts.js'

export default {
  name: 'SettingsModal',

  props: {
    item: Object
  },

  data: () => ({
    form: {
      user_type: null,
      name: '',
      surname: '',
      phone: '',
      email: '',
      password: null
    },
    types: Object.entries(USER_TYPES)
      .map(item => ({
        value: item[0],
        name: item[1]
      })),
    loading: false
  }),

  computed: {
    ...mapGetters([
      'groupsList',
      'user'
    ])
  },

  async mounted () {
    await this.fetchGroupsList()
    this.form.user_type = this.item.group_name
    this.form.name = this.item.first_name
    this.form.surname = this.item.last_name
    this.form.phone = this.item.phone
    this.form.email = this.item.email
  },

  methods: {
    ...mapActions(['fetchGroupsList', 'updateUser']),

    async onUpdate () {
      this.loading = true
      const payload = {
        email: this.form.email,
        first_name: this.form.name,
        last_name: this.form.surname,
        group_key: this.form.user_type,
        phone: this.form.phone
      }
      if (this.form.password) {
        payload.password = this.form.password
      }
      const { data, error } = await this.updateUser({ id: this.item.id, payload })
      this.loading = false
      if (error) {
        for (const key in data.errors) {
          this.$toasted.show(data.errors[key], {
            position: 'bottom-center',
            duration: 5000
          })
        }
      } else {
        this.$emit('close')
      }
    }
  }
}

</script>

<style lang="scss" scoped>
</style>
